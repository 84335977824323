import React, { Component, useEffect } from "react";
import "./cookie-policy.css";
import NavBar from "../components/navbar";
//import vote from "../images/bestweb-24-vote4us.webp"; //  BestWeb - logo
import TopWeb from "../images/TopWeb-May-2024.jpg"; //  TopWeb - logo
import Footer3CS from "../components/whitefooterbar";
import Arrow from "../template/arrow";
import { Helmet } from "react-helmet";

export default function CookiePolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  class BulletPoint extends Component {
    render() {
      return <li>{this.props.text}</li>;
    }
  }

  class CoBulletPoint extends Component {
    render() {
      return <li>{this.props.text}</li>;
    }
  }

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Diversified Conglomerates in Sri Lanka | Lalan Group</title>
        <meta
          name="title"
          content="Diversified Conglomerates in Sri Lanka | Lalan Group"
        />
        <meta
          name="description"
          content="Founded in the year 1940, Lalan group owns over 46 subsidiaries making it among the largest diversified conglomerates in Sri Lanka. "
        />
        <meta
          name="keywords"
          content="Diversified Conglomerates in Sri Lanka, Lalan Group"
        />
        <link
          rel="canonical"
          href="https://www.lalangroup.com/cookie-policy/"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Cookie Policy - Diversified Conglomerates in Sri Lanka | Lalan Group"
        />
        <meta
          property="og:url"
          content="https://www.lalangroup.com/cookie-policy/"
        />
        <meta
          property="og:site_name"
          content="Diversified Conglomerates in Sri Lanka | Lalan Group"
        />
      </Helmet>
      <div className="nav-color"></div> {/* NavBar color */}
      <NavBar />
      <br />
      <div
        style={{ marginTop: "100px", marginLeft: "10px", marginRight: "10px" }}
      >
        <div>
          <p className="mem1">Cookie Policy</p>
          <p className="mem2">
            This Cookies Policy explains what Cookies are and how We use them.
            You should read this policy so You can understand what type of
            cookies We use, or the information We collect using Cookies and how
            that information is used. Cookies do not typically contain any
            information that personally identifies a user, but personal
            information that we store about You may be linked to the information
            stored in and obtained from Cookies. For further information on how
            We use, store and keep your personal data secure, see our Privacy
            Policy. We do not store sensitive personal information, such as
            mailing addresses, account passwords, etc. in the Cookies We use.
          </p>
        </div>
        <div style={{ marginTop: "100px" }}>
          <p className="mem3">Interpretation and Definitions</p>
          <p className="mem4">Interpretation</p>
          <p className="mem5">
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>
        </div>
        <div style={{ marginTop: "50px" }}>
          <p className="mem4">Definitions</p>
          <p className="mem5">For the purposes of this Privacy Policy:</p>
          <p className="mem5">
            <ul className="bullet-points">
              <li>
                <strong>Company</strong> (referred to as either "the Company",
                "We", "Us" or "Our" in this Agreement) refers to Lalan Group,
                995B, Zone A, Export Processing Zone, Biyagama, Malwana, Sri
                Lanka.
              </li>
              <li>
                <strong>Cookies</strong> are small files that are placed on Your
                computer, mobile device or any other device by a website,
                containing the details of Your browsing history on that website
                among its many uses.
              </li>
              <li>
                <strong>Website</strong> refers to Lalan Group, accessible from
                https://lalangroup.lk/
              </li>
              <li>
                <strong>You</strong> means the individual accessing or using the
                Service, or the company, or other legal entity on behalf of
                which such individual is accessing or using the Service, as
                applicable.
              </li>
            </ul>
          </p>
        </div>
        <div style={{ marginTop: "100px" }}>
          <p className="mem3">The use of the Cookies</p>
          <p className="mem4">Types of Cookies We Use</p>
          <p className="mem5">
            Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
            remain on your personal computer or mobile device when You go
            offline, while Session Cookies are deleted as soon as You close your
            web browser.
          </p>
          <p className="mem5">
            We use both session and persistent Cookies for the purposes set out
            below:
          </p>
          <p className="mem5">
            <ul className="bullet-points">
              <li>
                <strong>Necessary / Essential Cookies</strong>
                <br />
                Type: Session Cookies
                <br />
                Administered by: Us
                <br />
                Purpose: These Cookies are essential to provide You with
                services available through the Website and to enable You to use
                some of its features. They help to authenticate users and
                prevent fraudulent use of user accounts. Without these Cookies,
                the services that You have asked for cannot be provided, and We
                only use these Cookies to provide You with those services.
              </li>
            </ul>
          </p>
          <p className="mem5">
            <ul className="bullet-points">
              <li>
                <strong>Functionality Cookies</strong>
                <br />
                Type: Persistent Cookies
                <br />
                Administered by: Us
                <br />
                Purpose: These Cookies allow us to remember choices You make
                when You use the Website, such as remembering your login details
                or language preference. The purpose of these Cookies is to
                provide You with a more personal experience and to avoid You
                having to re-enter your preferences every time You use the
                Website.
              </li>
            </ul>
          </p>
        </div>
        <div style={{ marginTop: "100px" }}>
          <p className="mem4">Your Choices Regarding Cookies</p>
          <p className="mem5">
            If You prefer to avoid the use of Cookies on the Website, first You
            must disable the use of Cookies in your browser and then delete the
            Cookies saved in your browser associated with this website. You may
            use this option for preventing the use of Cookies at any time.
          </p>
          <p className="mem5">
            If You do not accept Our Cookies, You may experience some
            inconvenience in your use of the Website and some features may not
            function properly. If You'd like to delete Cookies or instruct your
            web browser to delete or refuse Cookies, please visit the help pages
            of your web browser.
          </p>
          <p className="mem5">
            <ul className="bullet-points">
              <li>
                For the Chrome web browser, please visit this page from Google:
                <br />
                <a href="https://support.google.com/accounts/answer/32050">
                  <p className="mem9">
                    https://support.google.com/accounts/answer/32050
                  </p>
                </a>
              </li>
              <li>
                For the Internet Explorer web browser, please visit this page
                from Microsoft:
                <br />
                <a href="http://support.microsoft.com/kb/278835">
                  <p className="mem9">http://support.microsoft.com/kb/278835</p>
                </a>
              </li>
              <li>
                For the Firefox web browser, please visit this page from
                Mozilla:
                <br />
                <a href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored">
                  <p className="mem9">
                    https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored
                  </p>
                </a>
              </li>
              <li>
                For the Safari web browser, please visit this page from Apple:
                <br />
                <a href="https://support.google.com/accounts/answer/32050">
                  <p className="mem9">
                    https://support.google.com/accounts/answer/32050
                  </p>
                </a>
                <br />
                For any other web browser, please visit your web browser's
                official web pages.
              </li>
            </ul>
          </p>
        </div>
        <div style={{ marginTop: "100px" }}>
          <p className="mem3">Contact Us</p>
          <p className="mem5">
            If you have any questions about this Cookies Policy, You can contact
            us:
          </p>
        </div>
        <div>
          <p className="mem5">
            <ul className="bullet-points">
              <li>By email: info@lalangroup.com</li>
              <li>
                By visiting this page on our website:
                <a href="/contact-us/">
                  &nbsp; https://lalangroup.lk/contact-us/
                </a>
              </li>
              <li>By phone number: +94 114 311 200</li>
            </ul>
          </p>
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
          {/* BestWeb - start */}
          {/* <div>
            <a
              href="https://www.vote.bestweb.lk/site/www_lalangroup_lk"
              target="_blank"
            >
              <img className="logopic" src={vote} alt="logo" />
            </a>
          </div> */}
          {/* BestWeb - end */}

          {/* TopWeb - start */}
          <div>
            <a href="https://topweb.lk/may2024/lalangroup" target="_blank">
              <img
                style={{ marginLeft: "30px" }}
                className="logopic-2"
                src={TopWeb}
                alt="logo"
              />
            </a>
          </div>
          {/* TopWeb - end */}
        </div>
      </div>
      <div style={{ marginTop: "100px" }}>
        <Footer3CS />
      </div>
      <Arrow />
    </div>
  );
}
